import { Extension } from '@tiptap/core'
// import { Decoration, DecorationSet } from 'prosemirror-view'
// import { Node as ProsemirrorNode } from 'prosemirror-model'
// import { Plugin, PluginKey, TextSelection } from 'prosemirror-state'




const OPENAI_API_KEY = import.meta.env.VITE_OPENAI_API_KEY;
import { Configuration, OpenAIApi } from "openai";

const DEBUG = false;


function preparePrompt(editor, completionType = 'prompted') {
  var selectedText = window.getSelection().toString();
  console.log("Selected text: " + selectedText);


  let userPrompt = ""
  if (completionType === 'prompted') {
    var promptText = selectedText ? "Tell the AI how to modify the selection" : "Tell the AI what to add"
    userPrompt = prompt(promptText);

    if (!userPrompt) {
      return {}
    }
  }

  // if (!selectedText) {
  var anchor = editor.state.selection.anchor;
  // }
  let fullText = editor.getText();

  if (completionType === 'invent') {
    //remove the trailing slash from the fullText
    fullText = fullText.substring(0, fullText.length - 1);
  }

  // split the fullText into two parts: before and after the selectedText, or before and after the anchor if selectedText is empty
  var prefix = fullText.substring(0, editor.state.selection.from - 1);
  var suffix = fullText.substring(editor.state.selection.to - 1);

  // if the prefix ends with a trailing slash or a trailing slash and a newline, remove it
  if (prefix.endsWith("/")) {
    prefix = prefix.substring(0, prefix.length - 1);
  } else if (prefix.endsWith("/\n")) {
    prefix = prefix.substring(0, prefix.length - 2);
  }

  console.log("Anchor: " + anchor);
  console.log("userPrompt: " + userPrompt);
  console.log("Prefix: ", prefix)
  console.log("Suffix: ", suffix)

  return { prefix, suffix, selectedText, userPrompt };
}

async function completeSelection(promptObject) {
  if (DEBUG) {
    var response = {
      json() {
        return {
          choices: [
            {
              text: "<p>This is a test of the emergency broadcast system. This is only a test.</p>"
            }
          ]
        }
      }
    }

    return response
  }

  return fetch("/gpt3", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      promptObject
    ),
  })
}

async function generateImage(prompt) {

  if (DEBUG) {
    return {
      json() {
        return {
          data: [
            {
              url: "https://placeimg.com/640/480/any"
            }
          ]
        }
      }
    }
  }


  return fetch('/dalle', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      prompt: prompt,
    })
  })
}


export const Gpt = Extension.create({
  name: "gpt",

  addStorage() {
    return {
      loading: false,
    }
  },


  addKeyboardShortcuts() {
    return {
      'Mod-shift-l': () => this.editor.commands.gpt(),
    }
  },

  addCommands() {
    return {
      generateImage: () => ({ commands }) => {
        this.storage.loading = true

        var selectedText = window.getSelection().toString();

        console.log("Generating image: ", selectedText)

        generateImage(selectedText)
          .then(response => response.json())
          .then(json => {
            console.log(json)
            var url = json.data[0].url;
            console.log("imageUrl: ", url);
            console.log("Insert at:", this.editor.state.selection.$to.pos);

            this.editor.chain()
              .focus()
              .selectTextblockEnd()
              .createParagraphNear()
              .setImage({
                src: url,
                width: 512,
                height: 512
              }).run()

            this.storage.loading = false
            $.toast({
              class: 'success',
              message: "Done"
            })
          })
          .catch(error => {
            this.storage.loading = false
            $.toast({
              class: 'error',
              message: error
            })
          });

      },

      gpt: (completionType) => ({ commands }) => {
        console.log("GPT command", completionType);

        this.storage.loading = true
        const promptObject = preparePrompt(this.editor, completionType)
        console.log("Prompt: ", promptObject);

        if (Object.keys(promptObject).length > 0) {
          completeSelection(promptObject)
            .then((response) => response.json())
            .then((json) => {
              console.log("Prompt: ", json.prompt);

              var response = json.choices[0].text.trimStart().trimEnd();
              console.log("Output: ", response);

              this.editor.chain().focus().insertContent(response).run();
              // this.editor
              //   .chain()
              //   .focus()
              //   .selectTextblockEnd()
              //   .createParagraphNear()
              //   .insertContent(response)
              //   .run();

              this.storage.loading = false
              $.toast({
                class: 'success',
                message: "Done"
              })

            })
            .catch(error => {
              this.storage.loading = false
              $.toast({
                class: 'error',
                message: error
              })
            });
        } else {
          this.storage.loading = false
        }
      },
    }
  }
})