<script>
import Editor from "./components/Editor.vue";
import { useStorage } from "@vueuse/core";

export default {
  name: "DocumentEditor",
  components: {
    Editor,
  },
  props: {
    debug: {
      type: Boolean,
      default: false,
    },
    inputSelector: {
      type: String,
      default: null,
    },
  },
  data() {
    let $this = this;
    var inputElement = null;
    let inputBody = "";
    if ($this.inputSelector) {
      inputElement = document.querySelector($this.inputSelector);
      console.log(inputElement);
      inputBody = inputElement.value;
    } else {
      inputBody = useStorage("editorBody", "");
    }

    return {
      loading: false,
      body: inputBody,
      inputElement: inputElement,
    };
  },
  watch: {
    body(newVal, oldVal) {
      if (this.inputElement) {
        this.inputElement.value = newVal;
        this.inputElement.dispatchEvent(new Event("input", { bubbles: true }));
      }
    },
  },
  mounted() {},
  computed: {},
  methods: {
    // initPlaceholderContent() {
    //   var placeholders = [
    //     `<p>It was a typical Wednesday afternoon in the small town of Willow Creek. The sun was shining, the birds were chirping, and the streets were bustling with people going about their daily routines. But for 26-year-old Samantha, this day was anything but typical. She had just received some life-changing news that would turn her world upside down.</p>`,
    //     `<p>As I stood on the edge of the cliff, overlooking the breathtaking vista before me, I couldn't help but think about the journey that had brought me here.</p>`,
    //     `<p>The sun was setting behind the dark forest, casting a golden glow over the snow-covered fields</p>`,
    //     `<p>As he sat in his cell, staring out the tiny window at the gray sky beyond, Billy Pilgrim couldn't help but feel like he had somehow ended up in the wrong story.</p>`,
    //     `<p>As she stepped out onto the busy city street, Alice couldn't help but feel a mix of excitement and nervousness at the prospect of starting her new job</p>`,
    //   ];
    //   // select a random element from the array of placeholders
    //   var randomElement =
    //     placeholders[Math.floor(Math.random() * placeholders.length)];
    //   this.storage.body = randomElement;
    // },
  },
};
</script>

<template>
  <div>
    <editor v-model="body" />
  </div>
</template>

<style scoped>
p {
  line-height: 3em;
}

a.hovering {
  visibility: hidden;
}

.hover-parent:hover a.hovering {
  visibility: visible;
}
</style>
