// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

import { Application } from '@hotwired/stimulus'
import Autosave from 'stimulus-rails-autosave'



import Rails from '@rails/ujs';

if (!window._rails_loaded) {
  Rails.start();
}
window.Rails = Rails;


const application = Application.start()
application.register('autosave', Autosave)
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'


import { createApp } from 'vue'
import DocumentEditor from './../components/DocumentEditor.vue'

import ResizeTextarea from "resize-textarea-vue3";

const documents = document.querySelectorAll("document-editor");

documents.forEach((document) => {
  console.log(document.dataset);
  createApp(DocumentEditor,
    { ...document.dataset }
  ).mount(document);
})



// add document ready listener
document.addEventListener("DOMContentLoaded", () => {

  //init fomantic ui tabs menus
  $('.tabular.menu .item').tab();


})